"use strict";

exports.__esModule = true;
exports.getStyles = getStyles;
function hexToRgba(hex, opacity) {
  var returnValue = '';
  if (hex) {
    hex = hex.replace('#', '');
    var r = parseInt(hex.substring(0, 2), 16);
    var g = parseInt(hex.substring(2, 4), 16);
    var b = parseInt(hex.substring(4, 6), 16);
    returnValue = "rgba(" + r + ", " + g + ", " + b + ", " + opacity + ")";
  }
  return returnValue;
}
function getTitleFontName(_ref) {
  var galleryLayout = _ref.galleryLayout,
    titlePlacement = _ref.titlePlacement;
  return galleryLayout === 5 || titlePlacement !== 1 ? 'itemFontSlideshow' : 'itemFont';
}
function getDescriptionFontName(_ref2) {
  var galleryLayout = _ref2.galleryLayout,
    titlePlacement = _ref2.titlePlacement;
  return galleryLayout === 5 || titlePlacement !== 1 ? 'itemDescriptionFontSlideshow' : 'itemDescriptionFont';
}
function getStyles(styleParams, wixSdk) {
  return new Promise(function (resolve) {
    return resolve();
  }).then(function () {
    var getColor = function getColor(name, _default) {
      try {
        return styleParams[name] ? styleParams[name].value : _default;
      } catch (e) {
        return _default;
      }
    };
    var getFont = function getFont(name, _default) {
      try {
        return styleParams[name] || wixSdk.getStyleFontByReference(_default);
      } catch (e) {
        return _default;
      }
    };
    var color1 = hexToRgba(wixSdk.getColorByreference('color-1').value, 1);
    var color5 = hexToRgba(wixSdk.getColorByreference('color-5').value, 1);
    var paragraph2 = wixSdk.getStyleFontByReference('Body-M');
    var background = getColor('bgColorExpand', color1);
    var titleColor = getColor('titleColorExpand', color5);
    var descriptionColor = getColor('descriptionColorExpand', color5);
    var buttonColor = getColor('addToCartColorExpand', color1);
    var buttonBackground = getColor('addToCartBackColorExpand', color5);
    var titleFontExpand = getFont('titleFontExpand', 'Heading-M');
    var descriptionFontExpand = getFont('descriptionFontExpand', 'Body-M');
    var buttonFont = getFont('style.addToCartFontExpand', 'Body-M');
    var titleFont = getFont(getTitleFontName(styleParams), 'Heading-M');
    var descriptionFont = getFont(getDescriptionFontName(styleParams), 'Body-M');
    var styles = {
      gallery: {
        title: {
          font: titleFont
        },
        description: {
          font: descriptionFont
        }
      },
      title: {
        font: titleFontExpand,
        color: titleColor
      },
      description: {
        font: descriptionFontExpand,
        color: descriptionColor
      },
      button: {
        font: buttonFont,
        color: buttonColor,
        background: buttonBackground
      },
      background: {
        color: background
      }
    };
    return {
      hrefs: getFontsHrefs(wixSdk, titleFontExpand, descriptionFontExpand, paragraph2, buttonFont, titleFont, descriptionFont),
      styles: styles
    };
  });
}
function getFontsHrefs(wixSdk) {
  var googleHrefs = [];
  try {
    for (var _len = arguments.length, fonts = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
      fonts[_key - 1] = arguments[_key];
    }
    googleHrefs = fonts.map(function (font) {
      return font.fontFamily;
    }).map(function (fontFamily) {
      return getGoogleFontHref(fontFamily, wixSdk);
    }).filter(function (font) {
      return font;
    });
  } catch (e) {}
  return getWixFontsHrefs(wixSdk.wixApi).concat(googleHrefs);
}
function getWixFontsHrefs(wixApi) {
  var removeHttp = function removeHttp(href) {
    return href.replace('http:', '');
  };
  var removeHttps = function removeHttps(href) {
    return href.replace('https:', '');
  };
  return wixApi && wixApi.isMock ? wixApi.getFontsHrefs() : [].concat(document.styleSheets).map(function (_ref3) {
    var href = _ref3.href;
    return href;
  }).filter(function (href) {
    return href && href.toLowerCase().indexOf('font') >= 0;
  }).map(function (href) {
    return removeHttps(href);
  }).map(function (href) {
    return removeHttp(href);
  }).map(function (href) {
    return 'https:' + href;
  });
}
function getGoogleFontHref(fontFamily, wixSdk) {
  var font = getGoogleFonts(wixSdk).find(function (font) {
    return font.fontFamily === fontFamily;
  });
  return font ? 'https://fonts.googleapis.com/icon?family=' + font.cdnName : null;
}
function getGoogleFonts(wixSdk) {
  var _ref4;
  var editorFonts = wixSdk.getEditorFonts() || [];
  var wixFontsArrays = editorFonts.map(function (obj) {
    return obj.fonts;
  });
  var fonts = (_ref4 = []).concat.apply(_ref4, wixFontsArrays);
  var googleFonts = fonts.filter(function (font) {
    return font.provider === 'google';
  });
  return googleFonts;
}